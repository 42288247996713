@tailwind base;
@tailwind components;
@tailwind utilities;


@media only screen and (max-width: 1100px) {
    .container {
        width: 90%;
    }
    .menu-icon{
        display:block;
    }
    .navbar-content {
        padding: 20px 2px;
    }
    nav{
        justify-content: space-between;
    }
    .navigation--lists{
        display:none;
        position:absolute;
        background-color: #fff;
        padding:0px;
        margin:0px;
        justify-content: center;
        align-items: center;
    box-shadow: rgba(0,0,0,0.2);
    border: .2rem solid rgba(255, 255, 255, .5);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    transition: transform 0.4s ease-in-out;
    padding: 6rem 1.8rem;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 35%;
    right: 0;
    transform: scale(0);
    z-index: 50;
    }
 .navigation--items {
    font-size: 24px;
    color: #000;
 }
    nav .menu-icon{
        cursor:pointer;
        float:right;
        padding:28px 20px;
        position:relative;
        user-select: none;
    }
    nav .menu-icon .nav-icon{
        background-color:#ff6600;
        display: block;
        height: 3px;
        position:relative;
        transition: background 0.2s ease-out;
        width:24px;
        border-radius: 5px;
    }
    nav .menu-icon .nav-icon:before,
    nav .menu-icon .nav-icon:after{
        background: #ff6600;
        content:'';
        display:block;
        height:100%;
        position:absolute;
        transition:all ease-out 0.2s;
        width:100%;
        border-radius: 5px;
    }
    nav .menu-icon .nav-icon:before{
        top:6px;
    }
    nav .menu-icon .nav-icon:after{
        top:-6px;
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon{
        background:transparent;
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
        transform: rotate(-45deg);
        top:0;
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
        transform: rotate(45deg);
        top:0;
    }
    nav .menu-btn{
        display:none;
    }
    nav .menu-btn:checked ~ .navigation--lists{
        transform: scale(1);
    }
}

@media only screen and (max-width: 600px) {
    #main {
        height: 100vh;
    }
    .header-divide {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .header-heading {
        display: flex;
        justify-content: center;
        align-items:  center;
        flex-direction: column;
        max-width: 285px;
    }
    .header-heading h2 {
        font-size: 60px;
        text-align: center;
    }
    .header-heading .btn {
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
    }
    .rating {
        max-width: 100%;
    }
    .members-rating h2 {
        font-size: 40px;
    }
    .members-rating span {
        font-size: 16px;
    }
    .about-text {
        width: 100%;
    }
    .about-text h1 {
        font-size: 40px;
        text-align: center;
    }
    .about-text p {
        font-size: 18px;
    }
    .about-btn {
        width: 100%;
    }
    #choice {
        height: 100vh;
    }
    .choice--container {
        max-width: 100%;
    }
    .choice--container h2 {
        font-size: 40px;   
        text-align: center;
    }
    .choice--items {
        gap: 15px;
    }
    .choice--title {
        font-size: 20px;
    }
    .trainer--container,
    .pricing-container {
        flex-direction: column;
    }
    .trainer--items {
        width: 100%;
    }
    #services {
        padding: 50px 0;
    }
    #services h1,
    .moment-heading,
    .pricing--heading,
    .trainer--heading,
    .testimonial--heading,
    .contact--heading,
    .pricing--heading {
        font-size: 40px;
        text-align: center;
    }
    .a-container {
        flex-direction: column;
    }
    .a-box {
        width: 100%;
    }
}


@media only screen and (max-width: 1190px) {
    #main{
        height: 120vh;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex: 0 0 45%;
	}
    .a-b-img img{
		object-fit:contain;
    }
    .footer-container,
    .contact--container,
    .testimonial-container {
        flex-direction: column;
    }
    .testimonial-box {
        margin: 0 auto;
        width: 100%;
        /* width: 510px; */
    }
}



@media only screen and (max-width: 970px) {
    #about{
        padding: 65px 0 25px;
        height: auto;
	}
    .about-container {
        flex-direction: column-reverse;
		justify-content: center;
    }
	.about-text{
        flex: 1;
        width: 100%;
	}
	.about-text h1{
		font-size:4rem;
    }
    .about-text p{
        width: 100%;
    }
    .about-image img{
       width: 100%;
       height: auto;
    }
    #main {
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: 0;
    }
    .moment-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .trainer--items {
        flex: 0 0 45%;
    }
    .a-container {
        gap: 20px;
    }
    .a-box {
        flex: 0 0 48%;
        width: 100%;
        padding: 20px;
    }
    .input--group {
        flex-direction: column;
        gap: 0px;
    }
    .input--group input[type="text"],
    .input--group input[type="email"] {
        width: 100%;
    }
    #contact {
        padding: 50px 0 5%;
    }
    .tab {
        width: 100%;
    }
    .tab button {
        font-size: 24px;
        width: 100%;
    }
    .pricing-container {
        flex-direction: column;
    }
    .pricing-box {
        width: 100%;
        padding: 16px;
    }
    .pricing-box-heading {
        font-size: 32px;
    }
    .pricing-box-price {
        font-size: 28px;
    }
    .choice-title {
        font-size: 18px;
    }
   


    /* SUBSCRIPTION PART */
    #subscription {
        display: block;
    }
    .right-w {
        display: none;
    }
    .left-w {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 40px 20px 0;
    }
    .subscription--heading {
        font-size: 32px;
    }
    .relative-block {
        display: block;
    }
}


@media only screen and (max-width: 400px){
    .a-box{
        width: 100%;
        height:400px;
    }
}


@media only screen and (min-width: 1200px) {
    .container {
        width: 90%;
    }
}